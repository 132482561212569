<template>
  <el-dialog
    :title="dialogTitle"
    :visible.sync="displayModal"
    :close-on-click-modal="false"
    width="75%"
    append-to-body
  >
    <div v-loading="loading">
      <el-row type="flex" justify="center">
        <el-col :span="24">
          <el-form label-width="250px" :disabled="editDisabled">
            <el-row class="mt-2">
              <el-col :span="8">
                <el-form-item label="Schedule At">
                  <el-date-picker v-model="scheduledAt" type="datetime">
                  </el-date-picker>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="Send to">
                  <el-select v-model="sendToType" placeholder="Select a value">
                    <el-option label="All users" value="all"> </el-option>
                    <el-option label="By users ID" value="byUserId">
                    </el-option>
                    <el-option
                      label="By organizations ID"
                      value="byOrganizationId"
                    >
                    </el-option>
                    <el-option label="By workspaces ID" value="byWorkspaceId">
                    </el-option>
                    <el-option label="By market type" value="byMarket">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row
              v-if="sendToType && sendToType !== 'all'"
              type="flex"
              justify="start"
              align="top"
            >
              <el-col :offset="8" :span="12">
                <el-form-item v-if="sendToType === 'byMarket'">
                  <el-select v-model="sendToData">
                    <el-option
                      v-for="item in listOfferMarketType"
                      :key="item.value"
                      :value="item.value"
                      :label="item.label"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item v-else>
                  <el-input
                    type="textarea"
                    :rows="4"
                    placeholder="Add ids list separate with coma"
                    v-model="sendToData"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="16">
                <el-form-item label="Visual url">
                  <el-input
                    clearable
                    v-model="visualUrl"
                    placeholder="default to MZ logo"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-divider content-position="left">Translated title</el-divider>
            <el-row v-for="lang in existingLanguages" :key="'title_' + lang">
              <el-col :span="18">
                <el-form-item :label="lang.toUpperCase()">
                  <el-input
                    :value="title[lang]"
                    @input="updateTitle(lang, $event)"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-divider content-position="left"
              >Translated description</el-divider
            >
            <el-row
              v-for="lang in existingLanguages"
              :key="'description_' + lang"
            >
              <el-col :span="18">
                <el-form-item :label="lang.toUpperCase()">
                  <el-input
                    :value="description[lang]"
                    @input="updateDescription(lang, $event)"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-divider content-position="left"
              >Primary action (optionnel)</el-divider
            >
            <div
              v-for="lang in existingLanguages"
              :key="'primaryAction_' + lang"
            >
              <el-row>
                <el-col :offset="2" :span="2">
                  {{ lang.toUpperCase() }}
                </el-col>
                <el-col :span="14">
                  <el-form-item label="Label" label-width="50px">
                    <el-input
                      :value="primaryAction.label[lang]"
                      clearable
                      @input="updatePrimaryAction('label', lang, $event)"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :offset="4" :span="14">
                  <el-form-item label="Url" label-width="50px">
                    <el-input
                      :value="primaryAction.url[lang]"
                      clearable
                      @input="updatePrimaryAction('url', lang, $event)"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>

            <el-divider content-position="left"
              >Secondary action (optionnel)</el-divider
            >
            <div
              v-for="lang in existingLanguages"
              :key="'secondaryAction_' + lang"
            >
              <el-row>
                <el-col :offset="2" :span="2">
                  {{ lang.toUpperCase() }}
                </el-col>
                <el-col :span="14">
                  <el-form-item label="Label" label-width="50px">
                    <el-input
                      :value="secondaryAction.label[lang]"
                      clearable
                      @input="updateSecondaryAction('label', lang, $event)"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :offset="4" :span="14">
                  <el-form-item label="Url" label-width="50px">
                    <el-input
                      :value="secondaryAction.url[lang]"
                      clearable
                      @input="updateSecondaryAction('url', lang, $event)"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </el-form>
        </el-col>
      </el-row>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="onClickCancel" v-t="'public.alert.cancel'"></el-button>
      <el-button
        v-if="!editDisabled"
        type="primary"
        v-t="'public.alert.confirm'"
        :disabled="validateButtonDisabled"
        @click="onClickValidate"
      ></el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "EditScheduledNotificationModal",

  components: {},

  props: [],
  computed: {
    ...mapState("notification", ["scheduledNotification"]),
    ...mapState("app", ["existingLanguages"]),
    ...mapGetters("offer", ["listOfferMarketType"]),

    dialogTitle() {
      if (this.editDisabled) {
        return "Scheduled notification information";
      } else {
        return this.scheduledNotification._id
          ? "Edit scheduled notification"
          : "Create scheduled notification";
      }
    },

    scheduledAt: {
      get() {
        return this.scheduledNotification.scheduledAt;
      },
      set(val) {
        this.updateScheduledNotificationField({
          key: "scheduledAt",
          value: val,
        });
      },
    },
    sendToType: {
      get() {
        return this.scheduledNotification.sendToType;
      },
      set(val) {
        this.updateScheduledNotificationField({
          key: "sendToType",
          value: val,
        });

        if (val === "all") {
          this.updateScheduledNotificationField({
            key: "sendToData",
            value: "",
          });
        }
      },
    },
    sendToData: {
      get() {
        return this.scheduledNotification.sendToData;
      },
      set(val) {
        this.updateScheduledNotificationField({
          key: "sendToData",
          value: val,
        });
      },
    },
    title: {
      get() {
        return this.scheduledNotification.title;
      },
      set(val) {},
    },
    description: {
      get() {
        return this.scheduledNotification.description;
      },
      set(val) {},
    },
    visualUrl: {
      get() {
        return this.scheduledNotification.visualUrl;
      },
      set(val) {
        this.updateScheduledNotificationField({
          key: "visualUrl",
          value: val,
        });
      },
    },
    primaryAction: {
      get() {
        return this.scheduledNotification.primaryAction
          ? this.scheduledNotification.primaryAction
          : { label: "", url: "" };
      },
      set(val) {},
    },
    secondaryAction: {
      get() {
        return this.scheduledNotification.secondaryAction
          ? this.scheduledNotification.secondaryAction
          : { label: "", url: "" };
      },
      set(val) {},
    },

    validateButtonDisabled() {
      return this.loading === true || this.editDisabled;
    },
  },
  data() {
    return {
      loading: false,
      displayModal: false,
      editDisabled: false,
    };
  },

  methods: {
    ...mapActions({
      loadScheduledNotification: "notification/LOAD_SCHEDULED_NOTIFICATION",
      saveScheduledNotification: "notification/SAVE_SCHEDULED_NOTIFICATION",
    }),
    ...mapMutations({
      updateScheduledNotificationField:
        "notification/UPDATE_SCHEDULED_NOTIFICATION_FIELD",
      resetScheduledNotification: "notification/RESET_SCHEDULED_NOTIFICATION",
    }),

    hide() {
      this.displayModal = false;
    },
    show(notificationId) {
      this.loading = true;
      this.loadScheduledNotification(notificationId).then(() => {
        this.loading = false;
      });
      this.editDisabled = true;
      this.displayModal = true;
    },
    showCreate() {
      this.resetScheduledNotification();
      this.editDisabled = false;
      this.displayModal = true;

      console.log(
        "showCreate",
        JSON.parse(JSON.stringify(this.scheduledNotification))
      );
    },
    showEdit(notificationId) {
      this.loading = true;
      this.loadScheduledNotification(notificationId).then(() => {
        this.loading = false;
      });
      this.editDisabled = false;
      this.displayModal = true;
    },
    showDuplicate(notificationId) {
      this.loading = true;
      this.resetScheduledNotification();
      this.loadScheduledNotification(notificationId).then(() => {
        this.updateScheduledNotificationField({
          key: "_id",
          value: null,
        });
        this.updateScheduledNotificationField({
          key: "scheduledAt",
          value: "",
        });

        this.updateScheduledNotificationField({
          key: "sended",
          value: false,
        });
        this.updateScheduledNotificationField({
          key: "sendInProgress",
          value: false,
        });
        this.loading = false;
      });
      this.editDisabled = false;
      this.displayModal = true;
    },
    onClickCancel() {
      this.hide();
    },
    onClickValidate() {
      if (this.editDisabled) {
        this.hide();
        return;
      }

      this.loading = true;

      this.saveScheduledNotification().then((success) => {
        this.loading = false;
        if (success) {
          this.$emit("save", this.scheduledNotification);
          this.hide();
        }
      });
    },

    updateTitle(lang, value) {
      let newTitle = { ...this.title };
      newTitle[lang] = value;
      this.updateScheduledNotificationField({
        key: "title",
        value: newTitle,
      });
    },
    updateDescription(lang, value) {
      let newDescription = { ...this.description };
      newDescription[lang] = value;
      this.updateScheduledNotificationField({
        key: "description",
        value: newDescription,
      });
    },
    updatePrimaryAction(field, lang, value) {
      let newAction = JSON.parse(JSON.stringify(this.primaryAction));
      if (newAction[field] === "") {
        newAction[field] = {};
      }
      newAction[field][lang] = value ? value : "";
      this.updateScheduledNotificationField({
        key: "primaryAction",
        value: newAction,
      });
    },
    updateSecondaryAction(field, lang, value) {
      let newAction = JSON.parse(JSON.stringify(this.secondaryAction));
      if (newAction[field] === "") {
        newAction[field] = {};
      }
      newAction[field][lang] = value ? value : "";
      this.updateScheduledNotificationField({
        key: "secondaryAction",
        value: newAction,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.el-form-item {
  margin-bottom: 1rem;
}
</style>