import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import Utils from "~/common/utils/store";
/**
 * User store
 *
 * register methods in your components with import { mapGetters, mapActions, mapState, mapMutations } from "vuex";
 *
 * pick only the method you need
 *
   methods :{
    ...mapActions({
      loadNotifications:"notification/LOAD_NOTIFICATIONS",
      loadScheduledNotifications:"notification/LOAD_SCHEDULED_NOTIFICATIONS",
      countScheduledNotifications: "notification/COUNT_SCHEDULED_NOTIFICATIONS",
      loadScheduledNotification: "notification/LOAD_SCHEDULED_NOTIFICATION",
      saveScheduledNotification: "notification/SAVE_SCHEDULED_NOTIFICATION",
    }),
    ...mapMutations({
      addNotification:"notification/ADD_NOTIFICATION",
      updateScheduledNotificationField: "notification/UPDATE_SCHEDULED_NOTIFICATION_FIELD",
      resetScheduledNotification: "notification/RESET_SCHEDULED_NOTIFICATION",
      resetStateNotification:"notification/RESET_STATE"
    }),
  },

    computed: {
      ...mapState("notification", ["notifications", "limitDisplayNotifications", "scheduledNotifications", "scheduledNotificationsCount"]),
      ...mapGetters("notification", [ "getNbUnreadNotification" ]),
      }
    }
 */

/**
 * return default scheduledNotification object
 */
function initialScheduledNotificationState() {
  return {
    _id: null,
    title: "",
    description: "",
    visualUrl: "",
    primaryAction: {
      label: "",
      url: ""
    },
    secondaryAction: {
      label: "",
      url: ""
    },
    scheduledAt: null,
    sended: false,
    sendInProgress: false,
    nbCreatedNotification: 0,
    sendToType: "all",
    sendToData: ""
  }
}

const getDefaultState = () => {
  return {
    notifications: [],
    limitDisplayNotifications: 20,

    scheduledNotification: initialScheduledNotificationState(),
    scheduledNotifications: [],
    scheduledNotificationsCount: 0,
  };
};

// initial state
const state = getDefaultState;

// getters are functions
const getters = {
  getNbUnreadNotification: state => {
    return state.notifications === null
      ? 0
      : state.notifications
        .filter(notif => notif.read === false)
        .length;
  },
};

// mutations
const mutations = {
  UPDATE_ROOT_FIELD: (state, { key, value }) => {
    Utils.setProperty(key, state, value);
  },

  ADD_NOTIFICATION(state, notification) {
    state.notifications.unshift(notification);
    if (state.notifications.length > state.limitDisplayNotifications) {
      state.notifications = state.notifications.slice(0, state.limitDisplayNotifications);
    }
  },
  SET_NOTIFICATIONS(state, notifications) {
    state.notifications = notifications;
  },
  UPDATE_NOTIFICATION(state, notification) {
    const index = state.notifications.findIndex(notif => notif._id === notification._id);
    if (index >= 0) {
      Object.assign(state.notifications[index], notification);
    }
  },
  CLEAR_NOTIFICATIONS(state) {
    state.notifications = [];
  },
  SET_SCHEDULED_NOTIFICATIONS(state, notifications) {
    state.scheduledNotifications = notifications;
  },

  UPDATE_SCHEDULED_NOTIFICATION_FIELD: (state, { key, value }) => {
    Utils.setProperty(key, state.scheduledNotification, value);
  },
  ADD_SCHEDULED_NOTIFICATION: (state, payload) => {
    state.scheduledNotifications.push(payload.notification);
  },
  UPDATE_SCHEDULED_NOTIFICATION: (state, payload) => {
    const _index = state.scheduledNotifications.findIndex(notification => notification._id === payload.notification._id);
    if (_index >= 0) {
      Object.assign(state.scheduledNotifications[_index], payload.notification);
    }
  },

  RESET_SCHEDULED_NOTIFICATION: (state) => {
    state.scheduledNotification = Object.assign({}, initialScheduledNotificationState());
  },
  RESET_STATE: state => {
    Object.assign(state, getDefaultState());
  },
};

// actions are functions that cause side effects and can involve
// asynchronous operations.
const actions = {
  LOAD_NOTIFICATIONS(context) {
    return this.$axios.get(`/api/v1/notification/`, {
      params: {
        limit: context.state.limitDisplayNotifications
      }
    }).then(r => r.data)
      .then(
        notifications => {
          context.commit('SET_NOTIFICATIONS', notifications);
          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          context.commit('CLEAR_NOTIFICATIONS');
          return false;
        }
      );
  },
  SET_READ_NOTIFICATION(context, { notificationIds }) {
    return this.$axios.put('/api/v1/notification/read', {
      notificationIds
    }).then(r => r.data)
      .then(
        result => {
          if (result && result.success) {
            for (let notifId of notificationIds) {
              context.commit('UPDATE_NOTIFICATION', { _id: notifId, read: true });
            }
            return true;
          }
          else {
            return false
          }

        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );
  },
  CLEAR_NOTIFICATIONS(context) {
    context.commit('CLEAR_NOTIFICATIONS')
  },

  LOAD_SCHEDULED_NOTIFICATIONS: async function (context, payload = { filter: {} }) {

    return this.$axios.get("/api/v1/notification/scheduled?filter=" + JSON.stringify(payload.filter) + '&sort=' + payload.sort + '&limit=' + payload.limit + '&skip=' + payload.skip)
      .then(r => r.data)
      .then(
        notifications => {
          console.log("SET_SCHEDULED_NOTIFICATIONS", notifications);
          context.commit("SET_SCHEDULED_NOTIFICATIONS", notifications);
          this.dispatch('api/API_SUCCESS', { type: 'info', 'message': 'SCHEDULED_NOTIFICATIONS are loaded' });

          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);

          return false;
        }
      );
  },
  COUNT_SCHEDULED_NOTIFICATIONS: async function (context, payload) {
    return this.$axios
      .get('/api/v1/notification/scheduled/count?filter=' + JSON.stringify(payload.filter))
      .then(r => r.data)
      .then(
        count => {
          context.commit('UPDATE_ROOT_FIELD', { key: 'scheduledNotificationsCount', value: count });
          this.dispatch('api/API_SUCCESS', { type: 'info', message: 'COUNT SCHEDULED_NOTIFICATIONS are loaded' });
          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );
  },
  LOAD_SCHEDULED_NOTIFICATION: async function (context, payload) {
    return this.$axios.get("/api/v1/notification/scheduled/" + Utils.normalizePayloadURI(payload, 'notificationId'))
      .then(r => r.data)
      .then(
        notification => {
          this.commit("notification/UPDATE_SCHEDULED_NOTIFICATION_FIELD", { key: 'title', value: notification.title });
          this.commit("notification/UPDATE_SCHEDULED_NOTIFICATION_FIELD", { key: 'description', value: notification.description });
          this.commit("notification/UPDATE_SCHEDULED_NOTIFICATION_FIELD", { key: 'visualUrl', value: notification.visualUrl });
          this.commit("notification/UPDATE_SCHEDULED_NOTIFICATION_FIELD", { key: 'primaryAction', value: notification.primaryAction });
          this.commit("notification/UPDATE_SCHEDULED_NOTIFICATION_FIELD", { key: 'secondaryAction', value: notification.secondaryAction });
          this.commit("notification/UPDATE_SCHEDULED_NOTIFICATION_FIELD", { key: 'scheduledAt', value: notification.scheduledAt });
          this.commit("notification/UPDATE_SCHEDULED_NOTIFICATION_FIELD", { key: 'sended', value: notification.sended });
          this.commit("notification/UPDATE_SCHEDULED_NOTIFICATION_FIELD", { key: 'sendInProgress', value: notification.sendInProgress });
          this.commit("notification/UPDATE_SCHEDULED_NOTIFICATION_FIELD", { key: 'nbCreatedNotification', value: notification.nbCreatedNotification });
          this.commit("notification/UPDATE_SCHEDULED_NOTIFICATION_FIELD", { key: 'sendToType', value: notification.sendToType });
          this.commit("notification/UPDATE_SCHEDULED_NOTIFICATION_FIELD", { key: 'sendToData', value: notification.sendToData });

          this.commit("notification/UPDATE_SCHEDULED_NOTIFICATION_FIELD", { key: '_id', value: notification._id });

          this.dispatch('api/API_SUCCESS', { type: 'info', 'message': 'notification is loaded' });

          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );
  },
  SAVE_SCHEDULED_NOTIFICATION: function (context, payload) {
    let method = context.state.scheduledNotification._id === null ? "post" : "put";
    let updateId = context.state.scheduledNotification._id === null ? "" : context.state.scheduledNotification._id;

    return this.$axios[method]("/api/v1/notification/scheduled/" + updateId, context.state.scheduledNotification)
      .then(r => r.data)
      .then(
        notification => {
          this.commit("notification/UPDATE_SCHEDULED_NOTIFICATION_FIELD", { key: '_id', value: notification._id });

          this.commit("notification/UPDATE_SCHEDULED_NOTIFICATION_FIELD", { key: 'title', value: notification.title });
          this.commit("notification/UPDATE_SCHEDULED_NOTIFICATION_FIELD", { key: 'description', value: notification.description });
          this.commit("notification/UPDATE_SCHEDULED_NOTIFICATION_FIELD", { key: 'visualUrl', value: notification.visualUrl });
          this.commit("notification/UPDATE_SCHEDULED_NOTIFICATION_FIELD", { key: 'primaryAction', value: notification.primaryAction });
          this.commit("notification/UPDATE_SCHEDULED_NOTIFICATION_FIELD", { key: 'secondaryAction', value: notification.secondaryAction });
          this.commit("notification/UPDATE_SCHEDULED_NOTIFICATION_FIELD", { key: 'scheduledAt', value: notification.scheduledAt });
          this.commit("notification/UPDATE_SCHEDULED_NOTIFICATION_FIELD", { key: 'sended', value: notification.sended });
          this.commit("notification/UPDATE_SCHEDULED_NOTIFICATION_FIELD", { key: 'sendInProgress', value: notification.sendInProgress });
          this.commit("notification/UPDATE_SCHEDULED_NOTIFICATION_FIELD", { key: 'nbCreatedNotification', value: notification.nbCreatedNotification });
          this.commit("notification/UPDATE_SCHEDULED_NOTIFICATION_FIELD", { key: 'sendToType', value: notification.sendToType });
          this.commit("notification/UPDATE_SCHEDULED_NOTIFICATION_FIELD", { key: 'sendToData', value: notification.sendToData });

          if (updateId.length === 0) {
            this.commit("notification/ADD_SCHEDULED_NOTIFICATION", { notification });
          } else {
            this.commit("notification/UPDATE_SCHEDULED_NOTIFICATION", { notification });
          }

          this.dispatch('api/API_SUCCESS', { type: 'info', 'message': 'notification is saved', display: true });

          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response ? err.response.data : err);
          return false;
        }
      );
  },

};

let store = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default store;
